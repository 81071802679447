/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/28/2019
 * @Example 
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ButtonBasic from "./button-basic";
import Fab from "@material-ui/core/Fab";
import ReactLoading from "react-loading";
import withStyles from "@material-ui/core/styles/withStyles";

const hexToRGB = (hex, alpha) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    if (alpha) {
        return r + ", " + g + ", " + b + ", " + alpha;
    } else {
        return r + ", " + g + ", " + b;
    }
};
const styles = theme => ({
    btn_success: {
        '&:not(.btn-custom)': {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.getContrastText(theme.palette.success.main),
            '& svg': {
                '& path, circle, line': {
                    fill: theme.palette.getContrastText(theme.palette.success.main),
                    stroke: theme.palette.getContrastText(theme.palette.success.main)
                }
            },
            '&:hover': {
                backgroundColor: theme.palette.success.main,
                opacity: 0.7
            }
        },
        '&': {
            backgroundColor: 'transparent',
            color: theme.palette.success.main,
            '& svg': {
                '& path, circle, line': {
                    fill: theme.palette.success.main,
                    stroke: theme.palette.success.main
                }
            },
            '&:hover': {
                backgroundColor: 'rgba(' + hexToRGB(theme.palette.success.main, 0.17) + ')',
            }
        }
    },
    btn_info: {
        '&:not(.btn-custom)': {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.getContrastText(theme.palette.info.main),
            '& svg': {
                '& path, circle, line': {
                    fill: theme.palette.getContrastText(theme.palette.info.main),
                    stroke: theme.palette.getContrastText(theme.palette.info.main)
                }
            },
            '&:hover': {
                backgroundColor: theme.palette.info.main,
                opacity: 0.7
            }
        },
        '&': {
            backgroundColor: 'transparent',
            color: theme.palette.info.main,
            '& svg': {
                '& path, circle, line': {
                    fill: theme.palette.info.main,
                    stroke: theme.palette.info.main
                }
            },
            '&:hover': {
                backgroundColor: 'rgba(' + hexToRGB(theme.palette.info.main, 0.17) + ')',
            }
        }
    },
    btn_warning: {
        '&:not(.btn-custom)': {
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.getContrastText(theme.palette.warning.main),
            '& svg': {
                '& path, circle, line': {
                    fill: theme.palette.getContrastText(theme.palette.warning.main),
                    stroke: theme.palette.getContrastText(theme.palette.warning.main)
                }
            },
            '&:hover': {
                backgroundColor: theme.palette.warning.main,
                opacity: 0.7
            }
        },
        '&': {
            backgroundColor: 'transparent',
            color: theme.palette.warning.main,
            '& svg': {
                '& path, circle, line': {
                    fill: theme.palette.warning.main,
                    stroke: theme.palette.warning.main
                }
            },
            '&:hover': {
                backgroundColor: 'rgba(' + hexToRGB(theme.palette.warning.main, 0.17) + ')',
            }
        }
    },
    btn_danger: {
        '&:not(.btn-custom)': {
            backgroundColor: theme.palette.danger.main,
            color: theme.palette.getContrastText(theme.palette.danger.main),
            '& svg': {
                '& path, circle, line': {
                    fill: theme.palette.getContrastText(theme.palette.danger.main),
                    stroke: theme.palette.getContrastText(theme.palette.danger.main)
                }
            },
            '&:hover': {
                backgroundColor: theme.palette.danger.main,
                opacity: 0.7
            }
        },
        '&': {
            backgroundColor: 'transparent',
            color: theme.palette.danger.main,
            '& svg': {
                '& path, circle, line': {
                    fill: theme.palette.danger.main,
                    stroke: theme.palette.danger.main
                }
            },
            '&:hover': {
                backgroundColor: 'rgba(' + hexToRGB(theme.palette.danger.main, 0.17) + ')',
            }
        }
    },
});
class ButtonCustom extends Component {

    onClick() {
        const {loading} = this.props;
        if(this.props.onClick && !loading) this.props.onClick();
    }

    render() {
        const {classes, fab, color, name, loading, variant, margin, className, disabled, icon, ...props} = this.props;
        const styleFab = {
            ...(!color ? {backgroundColor: '#fff'} : {})
        };
        const listColorText = ["success", "info", "warning", "danger"];
        let _className = "btn-action";
        if (className) {
            _className += " " + className;
        }
        if (loading) {
            _className += " btn-loading";
        }
        if (variant === "custom") {
            _className += " btn-custom";

        }
        const custom_btn = listColorText.indexOf(color) > -1 ? classes["btn_" + color] : "";

        if (props.color) delete props.color;

        return (
            <React.Fragment>
                {!fab && <ButtonBasic  {...props}
                                       disabled={disabled}
                                       style={{
                                           ...styleFab,
                                           ...props.style
                                       }}
                                       {...(listColorText.indexOf(color) < 0 ? { color: color } : {})}
                                       className={(custom_btn ? custom_btn + " " : "") + _className}
                                       onClick={() => this.onClick()}
                                        {...(variant !== "custom" ? {variant: variant ? variant : "outlined"} : {variant: "text"})}
                                       margin={margin ? margin : "dense"}
                >
                    {(!loading && icon) && icon}
                    {loading && <ReactLoading type={'spokes'} height={16} width={16} color={color !== 'inherit' ? '#fafafa' : '#3c3c3c'} />}
                    {name && <span className={icon ? "mgl5" : ""}>{name}</span>}
                    {!name && <span style={{marginLeft: -5}}>&nbsp;</span>}
                </ButtonBasic>}
                {fab && <Fab
                    {...props}
                    disabled={disabled}
                    style={{
                        ...styleFab,
                        ...props.style
                    }}
                    {...(listColorText.indexOf(color) < 0 ? { color: color } : {})}
                    className={className + (loading ? " btn-loading" : "")}
                    onClick={()=>this.onClick()}
                    variant={variant ? variant : 'extended'}
                >
                    {(!loading && icon) && icon}
                    {loading && <ReactLoading type={'spokes'} height={16} width={16} color={color !== 'inherit' ? '#fafafa' : '#3c3c3c'} />}
                    {name && <span className={icon ? "mgl5" : ""}>{name}</span>}
                    {!name && <span style={{marginLeft: -5}}>&nbsp;</span>}
                </Fab>}
            </React.Fragment>
        );
    }
}

ButtonCustom.propTypes = {
    fab: PropTypes.bool,
    name: PropTypes.string,
    icon: PropTypes.any,
    variant: PropTypes.string,
    margin: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,

    onClick: PropTypes.func,
};

export default withStyles(styles)(ButtonCustom);