/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/4/2019
 * @Example
 */

import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Config from "../../../../config";
import {withStyles} from "@material-ui/styles";
import InputLabel from "@material-ui/core/InputLabel";
import {TagBox, SelectBox, Template, LoadIndicator} from "devextreme-react";
import FormControl from "@material-ui/core/FormControl";
import {FormHelperText} from "@material-ui/core";
import LoadMore from "./loadmore";
import Employees from "./employees";

const styles = {
    root: {
        backgroundColor: "#FFFFFF"
    },
    loadIndicator: {
        height: '100%'
    }
};
class Combo extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            shrink: props.shrink,
            value: props.value,
            opened: false,
            loading: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.dataSource !== this.props.dataSource || JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value)) {
            this.setState({
                value: this.props.value
            })
        }
    }
    componentDidMount() {
        this.setState({loading: true});
        // setTimeout(() => {
        //     this.setState({loading: false});
        // }, 10000);
    }

    // onFocusIn = () => {
    //     console.log('test', this.isFirst);
    //     if (!this.isFirst) {
    //         this.setState({
    //             shrink:  true,
    //             focused: true,
    //         });
    //     }
    //     this.isFirst = false;
    // };
    //
    // onFocusOut = () => {
    //     console.log('test2', this.isFirst);
    //     const {value} = this.state;
    //     this.setState({
    //         shrink: Array.isArray(value) ? value.length > 0 : !!value || this.props.shrink,
    //         focused: false
    //     });
    // };

    onOpened = (e) => {
        const {onOpened} = this.props;
        this.setState({opened: true});
        if (onOpened) onOpened(e);
    };

    onClosed = (e) => {
        const {onClosed} = this.props;
        this.setState({opened: false});
        if (onClosed) onClosed(e);
    };

    onValueChanged = (e) => {
        const {onValueChanged} = this.props;
        this.setState({
            value: e.value,
            shrink: Array.isArray(e.value) ? e.value.length > 0 : !!e.value || this.props.shrink
        });
        if (onValueChanged) onValueChanged(e);
    };

    onSelectionChanged = (e) => {
        const {onSelectionChanged} = this.props;
        if (onSelectionChanged) onSelectionChanged(e);
    };

    onInput = (e) => {
        if (!e) return false;
        const {onInput, selectProps} = this.props;
        if (e && e.event) {
            e.event.target.value = e.event.target.value.normalize();
        }
        if (onInput) {
            onInput(e);
        } else if (selectProps && selectProps.onInput) {
            selectProps.onInput(e);
        }
    };

    renderLoadIndicator = () => {
        const {classes, loading} = this.props;
        return (
            <div className={"display_row align-center " + classes.loadIndicator}>
                <LoadIndicator visible={loading} width={24} height={24} />
            </div>
        );
    };

    render() {
        const {value, shrink, opened} = this.state;
        const {
            label, style, className, margin, classes, name, multiple, error, helperText,
            height, placeholder, dataSource, stylingMode, showClearButton, acceptCustomValue,
            openOnFieldClick, showDropDownButton, disabled, noDataText, displayExpr, valueExpr,
            InputLabelProps, selectProps, searchEnabled, searchExpr, searchMode, itemRender, fieldRender,
            wrapItemText, required, loading, reference
        } = this.props;

        const variant = stylingMode === 'underlined' ? "standard" : (stylingMode === 'filled' ? 'filled' : 'outlined');
        const _shrink = (Array.isArray(value) ? value.length > 0 : !Config.isEmpty(value, false)) || shrink || opened;

        return (
            <React.Fragment>
                <FormControl
                    error={!!error && !disabled && (Array.isArray(value) ? value.length <= 0 : Config.isEmpty(value, false))}
                    className={className}
                    classes={{root: classes.root}}
                    variant={variant}
                    disabled={disabled || loading}
                    margin={margin ? margin : "dense"}
                    fullWidth={true} style={style}>
                    {label &&
                    <InputLabel
                        required={required}
                        className={opened ? "Mui-focused" : ""}
                        shrink={_shrink}
                        disabled={disabled || loading}
                        {...InputLabelProps}
                    >{label}</InputLabel>}
                    {multiple ?
                        (
                            <TagBox
                                isValid={!error || disabled || (Array.isArray(value) ? value.length > 0 : !Config.isEmpty(value, false))}
                                acceptCustomValue={acceptCustomValue}
                                dataSource={dataSource}
                                disabled={disabled || loading}
                                displayExpr={displayExpr}
                                valueExpr={valueExpr}
                                height={height}
                                name={name}
                                noDataText={noDataText ? noDataText : Config.lang("DHR_Khong_co_du_lieu")}
                                placeholder={placeholder}
                                openOnFieldClick={typeof openOnFieldClick !== "undefined" ? openOnFieldClick : true}
                                searchEnabled={typeof searchEnabled !== "undefined" ? searchEnabled : true }
                                searchExpr={searchExpr}
                                searchMode={searchMode ? searchMode : "contains"}
                                showClearButton={showClearButton}
                                showDropDownButton={typeof showDropDownButton !== "undefined" ? showDropDownButton : true}
                                stylingMode={stylingMode ? stylingMode : "outlined"}
                                value={value}
                                wrapItemText={typeof wrapItemText === "undefined" ? true : wrapItemText}
                                onFocusIn={this.onFocusIn}
                                onFocusOut={this.onFocusOut}
                                onOpened={this.onOpened}
                                onClosed={this.onClosed}
                                onValueChanged={this.onValueChanged}
                                onSelectionChanged={this.onSelectionChanged}
                                {...(!disabled && loading ? {dropDownButtonTemplate: "conditionalIcon"} : {})}
                                itemRender={itemRender}
                                fieldRender={fieldRender}
                                {...selectProps}
                                onInput={this.onInput}
                            >
                                <Template name='conditionalIcon' render={this.renderLoadIndicator} />
                            </TagBox>
                        ) : (
                            <SelectBox
                                ref={ref=>reference && reference(ref)}
                                isValid={!error || disabled || !!(Array.isArray(value) ? value.length > 0 : !Config.isEmpty(value, false))}
                                acceptCustomValue={acceptCustomValue}
                                dataSource={dataSource}
                                disabled={disabled || loading}
                                displayExpr={displayExpr}
                                valueExpr={valueExpr}
                                height={height}
                                name={name}
                                noDataText={noDataText ? noDataText : Config.lang("DHR_Khong_co_du_lieu")}
                                placeholder={placeholder}
                                openOnFieldClick={typeof openOnFieldClick !== "undefined" ? openOnFieldClick : true}
                                searchEnabled={typeof searchEnabled !== "undefined" ? searchEnabled : true }
                                searchExpr={searchExpr}
                                searchMode={searchMode ? searchMode : "contains"}
                                showClearButton={showClearButton}
                                showDropDownButton={typeof showDropDownButton !== "undefined" ? showDropDownButton : true}
                                stylingMode={stylingMode ? stylingMode : "outlined"}
                                value={value}
                                wrapItemText={typeof wrapItemText === "undefined" ? true : wrapItemText}
                                onFocusIn={this.onFocusIn}
                                onFocusOut={this.onFocusOut}
                                onOpened={this.onOpened}
                                onClosed={this.onClosed}
                                onValueChanged={this.onValueChanged}
                                onSelectionChanged={this.onSelectionChanged}
                                {...(!disabled && loading ? {dropDownButtonTemplate: "conditionalIcon"} : {})}
                                itemRender={itemRender}
                                fieldRender={fieldRender}
                                {...selectProps}
                                onInput={this.onInput}
                            >
                                <Template name='conditionalIcon' render={this.renderLoadIndicator} />
                            </SelectBox>
                        )}
                        <FormHelperText>{typeof error === "string" && (Array.isArray(value) ? value.length <= 0 : Config.isEmpty(value, false)) ? error : helperText}</FormHelperText>
                </FormControl>
            </React.Fragment>
        );
    }
}

Combo.defaultProps = {
    placeholder: null
};
Combo.propTypes = {
    label: PropTypes.any,
    shrink: PropTypes.bool,
    style: PropTypes.any,
    className: PropTypes.string,
    height: PropTypes.any,
    margin: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    multiple: PropTypes.bool,
    error: PropTypes.any,
    helperText: PropTypes.bool,
    wrapItemText: PropTypes.bool,
    required: PropTypes.bool,
    loading: PropTypes.bool,

    value: PropTypes.any,
    displayExpr: PropTypes.any,
    valueExpr: PropTypes.any,
    acceptCustomValue: PropTypes.bool,
    openOnFieldClick: PropTypes.bool,
    showDropDownButton: PropTypes.bool,
    dataSource: PropTypes.any,
    placeholder: PropTypes.string,
    stylingMode: PropTypes.string,
    showClearButton: PropTypes.bool,
    noDataText: PropTypes.string,
    searchEnabled: PropTypes.bool,
    searchExpr: PropTypes.any,
    searchMode: PropTypes.string,
    itemRender: PropTypes.any,
    fieldRender: PropTypes.any,

    InputLabelProps: PropTypes.any,
    selectProps: PropTypes.any,

    onInput: PropTypes.func,
    onFocusIn: PropTypes.func,
    onFocusOut: PropTypes.func,
    onOpened: PropTypes.func,
    onClosed: PropTypes.func,
    onSelectionChanged: PropTypes.func,
    onValueChanged: PropTypes.func,

};

Combo.Employees = Employees;
Combo.LoadMore = LoadMore;
export default withStyles(styles)(Combo);
