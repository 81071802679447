/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/25/2019
 * @Example
 */

import React from "react";
import {Collapse, Fade, Grow, Slide} from "@material-ui/core";
import Config from "../../../config";
import {getDataForm} from "../../../redux/main/main_sagas";
import moment from "moment";

export const popupTransitions = {
    Slide: React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    }),
    Grow: React.forwardRef(function Transition(props, ref) {
        return <Grow ref={ref} {...props} />;
    }),
    Fade: React.forwardRef(function Transition(props, ref) {
        return <Fade ref={ref} {...props} />;
    }),
    Collapse: React.forwardRef(function Transition(props, ref) {
        return <Collapse ref={ref} {...props} />;
    })
};

export function getObjectValue(object, key, defaultValue) {
    if (object && object[key])  return object[key];
    if (defaultValue !== undefined)
        return defaultValue;
    else
        return "";
}

export function convertDate(value, defaultValue, format) {
    if (!value) return defaultValue ? defaultValue : null;
    if (value.indexOf('T') > -1 || value.indexOf('Z') > -1)
        return moment.utc(value).format(format ? format : "DD/MM/YYYY");
    else
        return moment(value).format(format ? format : "DD/MM/YYYY");
}

export function getObjectFromArray(array, key, value, defaultValue) {
    if (!Array.isArray(array)) return null;
    const _filter = array.filter((e) => {return e[key] === value});
    if (_filter.length > 0) {
        return _filter[0];
    } else if (defaultValue !== undefined) {
        return defaultValue;
    } else {
        return null;
    }
}

export function getExtFile(fileName) {
    if (!fileName) return "";
    const arrNames = fileName.split('.');
    return arrNames.length > 1 ? arrNames[arrNames.length - 1] : "";
}

export function updateCurrentForm(path) {
    let result = null;
    const formID = this.getFormIDFromPath(path);
    if (!formID) return null;
    if (Config.getLocalStorage('MENUDHR')) {
        const menu = JSON.parse(Config.getLocalStorage('MENUDHR'));
        const listMenu = menu.menu ? menu.menu : null;
        if (listMenu) {
            const menu = this.getMenuItem(listMenu, formID);
            if (menu && menu.form) {
                result = {
                    preForm: menu.preForm,
                    Form: menu.form
                };
            }
        }

        const listEss = menu.listESS ? menu.listESS : null;
        if (listEss && !result) {
            for (let i = 0; i < listEss.length; i++) {
                if (formID.indexOf(listEss[i].FormActive) > -1) {
                    result = {
                        preForm: "",
                        Form: listEss[i]
                    };
                    break;
                }
            }
        }
        const listMss = menu.listMSS ? menu.listMSS : null;
        if (listMss && !result) {
            for (let i = 0; i < listMss.length; i++) {
                if (formID.indexOf(listMss[i].FormActive) > -1) {
                    result = {
                        preForm: "",
                        Form: listMss[i]
                    };
                    break;
                }
            }
        }

    }
    return result;
}

export function getMenuItem(menuItem, formID) {
    if (!menuItem || menuItem.length === 0) return null;
    let tmpMenu = null;
    for (let i = 0; i < menuItem.length; i++) {
        if (menuItem[i].FormActive && formID.indexOf(menuItem[i].FormActive) > -1) {
            tmpMenu = {form: menuItem[i], preForm: null};
            break;
        } else {
            const menu = this.getMenuItem(menuItem[i].children, formID);
            if (menu) {
                tmpMenu = {form: menu.form, preForm: menuItem[i]};
                break;
            }
        }
    }
    if (tmpMenu) return tmpMenu;
    return null;
}

export function getActiveFormTitle() {
    if (Config.getLocalStorage('formActive')) {
        const formActive = JSON.parse(Config.getLocalStorage('formActive'));
        return formActive.Form.FormDesc;
    }
    return "";
}

/**
 * Check is ESS...
 * @param path
 * @returns {boolean}
 */
export function isSelfServices(path) {
    let result = false;
    const formID = this.getFormIDFromPath(path);
    if (!formID) return false;
    if (Config.getLocalStorage('MENUDHR')) {
        const menu = JSON.parse(Config.getLocalStorage('MENUDHR'));
        const listMenu = menu.menu ? menu.menu : null;
        if (listMenu) {
            const menu = this.getMenuItem(listMenu, formID);
            if (menu && menu.form) {
                result = true;
            }
        }

        const listEss = menu.listESS ? menu.listESS : null;
        if (listEss && !result) {
            for (let i = 0; i < listEss.length; i++) {
                if (formID.indexOf(listEss[i].FormActive) > -1) {
                    result = true;
                    break;
                }
            }
        }
        const listMss = menu.listMSS ? menu.listMSS : null;
        if (listMss && !result) {
            for (let i = 0; i < listMss.length; i++) {
                if (formID.indexOf(listMss[i].FormActive) > -1) {
                    result = true;
                    break;
                }
            }
        }

    }
    return result;
}

/**
 * Check is ESS...
 * @param path
 * @returns {boolean}
 */
export function isEss(path) {
    let result = false;
    const formID = this.getFormIDFromPath(path);
    if (formID && Config.getLocalStorage('MENUDHR')) {
        const menu = JSON.parse(Config.getLocalStorage('MENUDHR'));
        const listEss = menu.listESS ? menu.listESS : null;
        if (listEss) {
            for (let i = 0; i < listEss.length; i++) {
                if (formID.indexOf(listEss[i].FormActive) > -1) {
                    result = true;
                    break;
                }
            }
        }
    }
    return result;
}

/**
 * Check is MSS...
 * @param path
 * @returns {boolean}
 */
export function isMss(path) {
    let result = false;
    const formID = this.getFormIDFromPath(path);
    if (formID && Config.getLocalStorage('MENUDHR')) {
        const menu = JSON.parse(Config.getLocalStorage('MENUDHR'));
        const listMss = menu.listMSS ? menu.listMSS : null;
        if (listMss) {
            for (let i = 0; i < listMss.length; i++) {
                if (formID.indexOf(listMss[i].FormActive) > -1) {
                    result = true;
                    break;
                }
            }
        }
    }
    return result;
}

export async function getBreadcumbs(formID) {
    if (!formID) return null;
    let breadcrumb = Config.getLocalStorage('breadcrumbHR') ? JSON.parse(Config.getLocalStorage('breadcrumbHR')) : [];
    if (this.isSelfServices(formID)) {
        Config.removeLocalStorage('breadcrumbHR');
        breadcrumb = [];
        if (Config.helpers.isMss(formID)) {
            breadcrumb.push({FormID: "MSS", FormName: "MSS", Link: Config.getRootPath()});
        } else if (Config.helpers.isEss(formID)) {
            breadcrumb.push({FormID: "ESS", FormName: "ESS", Link: Config.getRootPath()});
        }

        const menu = this.updateCurrentForm(formID);
        if (menu && menu.preForm) {
            const formActive = menu.preForm.FormActive;
            const formName = menu.preForm.FormDesc;
            breadcrumb.push({
                FormID: formActive,
                FormName: formName,
                Link: formActive ? Config.getRootPath() + formActive : ""
            });
        }
        if (menu && menu.Form) {
            const formActive = menu.Form.FormActive;
            const formName = menu.Form.FormDesc;
            breadcrumb.push({
                FormID: formActive,
                FormName: formName,
                Link: formActive ? Config.getRootPath() + formActive : ""
            });
        }
    } else {
        const indx = breadcrumb.findIndex(item => item.FormID === formID);
        if (indx !== -1) {
            breadcrumb.splice(indx + 1);
        } else {
            const dataForm = await getDataForm({FormID: formID, Language: Config.language || '84'});
            if (dataForm && dataForm.data) {
                const formActive = dataForm.FormActive ? dataForm.FormActive : formID;
                const formName = dataForm.FormDesc ? dataForm.FormDesc : formID;
                if (formName) {
                    breadcrumb.push({
                        FormID: formActive,
                        FormName: formName,
                        Link: formActive ? Config.getRootPath() + formActive : ""
                    });
                }
            }
        }
    }
    Config.setLocalStorage('breadcrumbHR', JSON.stringify(breadcrumb));
    return breadcrumb;
}

export function getFormIDFromPath(path) {
    let uri = path.replace(Config.getRootPath(), '');
    uri = uri.replace('/', '').split('?');
    return uri.length > 0 ? uri[0] : '';
}

export const listExpandContent = [
    //list form id allow expand content area...
    "W75F2000", "W09F2000"
];
export const listFormIgnoreTracking = [
    //list form id ignore tracking...
];

export function getFileInfomations(files) {
    if (Array.isArray(files)) {
        if (files.length > 0) {
            let _files = [];
            files.forEach((_f) => {
                const _convertedFile = {
                    ..._f,
                    fileName: _f.FileName ? _f.FileName : (_f.name ? _f.name : (_f.fileName ? _f.fileName : "")),
                    fileExt: _f.FileExt ? _f.FileExt : (_f.ext ? _f.ext : (_f.fileExt ? _f.fileExt : "")),
                    fileType: _f.FileType ? _f.FileType : (_f.type ? _f.type : (_f.fileType ? _f.fileType : "")),
                    fileSize: _f.FileSize ? _f.FileSize : (_f.size ? _f.size : (_f.fileSize ? _f.fileSize : "")),
                    url: _f.URL ? _f.URL : (_f.url ? _f.url : ""),
                };
                _files.push(_convertedFile);
            });
            return _files;
        }
    } else {
        return {
            ...files,
            fileName: files.FileName ? files.FileName : (files.name ? files.name : (files.fileName ? files.fileName : "")),
            fileExt: files.FileExt ? files.FileExt : (files.ext ? files.ext : (files.fileExt ? files.fileExt : "")),
            fileType: files.FileType ? files.FileType : (files.type ? files.type : (files.fileType ? files.fileType : "")),
            fileSize: files.FileSize ? files.FileSize : (files.size ? files.size : (files.fileSize ? files.fileSize : "")),
            url: files.URL ? files.URL : (files.url ? files.url : ""),
        };
    }
    return [];
}
