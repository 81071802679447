/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 8/17/2020
 * @Example 
 */


import React from "react";
import {withStyles} from "@material-ui/core";
import propTypes from "prop-types";
import {compose} from "redux";
import Icon from '@material-ui/core/Icon';
import InlineSVG from "react-inlinesvg";
import {Image} from "react-bootstrap";
import listDefined from "./init";

const styles = theme => ({
    icon: {
        width: "auto",
        height: "auto",
        cursor: "pointer"
    },
    primary: {
        color: theme.palette.primary.main,
        "& path, & circle, & line": {
            fill: theme.palette.primary.main
        },
    },
    success: {
        color: theme.palette.success.main,
        "& path, & circle, & line": {
            fill: theme.palette.success.main
        },
    },
    danger: {
        color: theme.palette.danger.main,
        "& path, & circle, & line": {
            fill: theme.palette.danger.main
        },
    },
    info: {
        color: theme.palette.info.main,
        "& path, & circle, & line": {
            fill: theme.palette.info.main
        },
    },
    warning: {
        color: theme.palette.warning.main,
        "& path, & circle, & line": {
            fill: theme.palette.warning.main
        },
    },

    primary_hover: {
        "&:hover": {
            color: theme.palette.primary.main,
            "& path, & circle, & line": {
                fill: theme.palette.primary.main
            },
        }
    },
    success_hover: {
        "&:hover": {
            color: theme.palette.success.main,
            "& path, & circle, & line": {
                fill: theme.palette.success.main
            },
        }

    },
    danger_hover: {
        "&:hover": {
            color: theme.palette.danger.main,
            "& path, & circle, & line": {
                fill: theme.palette.danger.main
            },
        }
    },
    info_hover: {
        "&:hover": {
            color: theme.palette.info.main,
            "& path, & circle, & line": {
                fill: theme.palette.info.main
            },
        }
    },
    warning_hover: {
        "&:hover": {
            color: theme.palette.warning.main,
            "& path, & circle, & line": {
                fill: theme.palette.warning.main
            },
        }
    },
});

class Icons extends React.Component {

    getDefineIcons = () => {
        const {name : _name, src, color, colorHover, width, height, typeIcon} = this.props;
        const name = typeIcon !== "filled" ? _name : _name + "_filled";
        if (!name && !src) return {src: "", color, colorHover, width, height};
        if (src) return {src: src, color, colorHover, width, height};
        if (!name) return null;
        // const icon = listIcons.find(l => l.name === name);
        try {
            const define = listDefined && listDefined[name] ? listDefined[name] : null;
            const srcIcon = define && define.src ? define.src : require('./_icons/' + name + ".svg");
            return {
                name: name || "",
                src: srcIcon || "",
                color: color || (define?.color || "default"),
                colorHover: colorHover || (define?.colorHover || "default"),
                width: width ? width : (define?.width || 20),
                height: height ? height : (define?.height || 20),
            };
        } catch (e) {
            return {src: "", color, colorHover, width, height};
        }
    };

    render() {
        const {classes, name, type, fontSize, className, style, src : _src,
            component, children
        } = this.props;
        if (!_src && !className && !name && !component && !children) return null;
        let _className = className + " icons-ui " + classes.icon;

        const {src, color, colorHover, width, height} = this.getDefineIcons();

        if (color && classes[color]) _className += " " + classes[color];
        if (colorHover && classes[colorHover + "_hover"]) _className += " " + classes[colorHover + "_hover"];

        return (
            <>
                {src ? (
                    type === "inline" ? <InlineSVG uniquifyIDs={true} src={src} width={width} height={height} style={style} className={_className}/>
                    :
                        <Image src={src} width={width} height={height} style={style} className={_className}/>
                ) : (component || children) ? (
                    <div className={_className} style={style}>
                        {component && component}
                        {(children && !component) && children}
                    </div>
                ) : (
                    <Icon fontSize={fontSize} className={_className} style={style} />
                    )
                }
            </>
        );
    }
}
Icons.defaultProps = {
    type: "inline",
    typeIcon: "normal",
    color: "default",
    className: "",
};
Icons.propTypes = {
    name: propTypes.string,
    component: propTypes.any,
    className: propTypes.any,
    style: propTypes.any,
    src: propTypes.string,
    width: propTypes.any,
    height: propTypes.any,
    disabled: propTypes.bool,
    color: propTypes.any,
    colorHover: propTypes.any,
    fontSize: propTypes.string, //for Icon tag
    type: propTypes.oneOf(["normal", "inline"]),
    typeIcon: propTypes.oneOf(["normal", "filled"])
};

export default compose(withStyles(styles))(Icons)