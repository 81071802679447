/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/4/2019
 * @Example 
 */

import React from "react";
import PropTypes from "prop-types";
import {Image} from "react-bootstrap";
import {withStyles} from "@material-ui/styles";
import {Loading} from "../loading/loading";

const styles = {
    image: {
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
        margin: '0 auto',
        zIndex: 2
    },
    loading: {
        position: 'absolute',
        zIndex: 1
    },
    view: {
        zIndex: 2
    }
};
class FileViewer extends React.Component {

    componentDidMount() {
        const {isLocal, onClose} = this.props;
        if (isLocal) {
            const el = document.getElementById("linkDownload");
            if (el) {
                if (onClose) onClose();
                el.click();
            }
        }
    }

    getViewer = (filePath, fileType) => {
        const {classes, width, fileName,viewerProps, isLocal} = this.props;
        switch (fileType) {
            case 'jpg':
            case 'jpeg':
            case 'gif':
            case 'bmp':
            case 'png': {
                return <Image src={filePath}
                              {...viewerProps}
                              className={classes.image + (viewerProps && viewerProps.className ? viewerProps.className : '')}
                               />;
            }
            case 'pdf':
            case 'ppt':
            case 'pptx':
            case 'doc':
            case 'docx':
            case 'xls':
            case 'xlsx':
            case 'csv': {
                const time = (new Date()).getTime();
                return !isLocal ? (<iframe src={`https://docs.google.com/gview?url=${filePath}&embedded=true&time=${time}`} frameBorder={0}
                               title={fileName}
                               className={classes.view}
                               width={width ? width : '100%'} height={'100%'} {...viewerProps} />
                    ) : (
                    <a id={"linkDownload"} href={filePath} download={fileName}>{fileName}</a>
                );
            }
            case 'mp4': {
                return (
                        <video width="auto" height="auto" controls="controls"
                               className={classes.view}
                               autoPlay={true}>
                            <source src={filePath} type="video/mp4" />
                        </video>
                    )
            }
            default: {
                return <iframe title={fileName} className={classes.view} src={filePath} frameBorder={0}
                               width={width ? width : '100%'} height={'100%'} {...viewerProps} />
            }
        }
    };

    render() {
        const {classes, filePath, fileType, height, style, className, isLoading} = this.props;
        const _height = typeof height === "number" ? height + 'px' : height;
        console.log(filePath, fileType);
        return (
            <div className={'file-viewer text-center display_row align-center valign-middle' + (className ? ' ' + className : '')}
                 style={{
                     height: _height,
                     ...style
                 }}>
                {isLoading && <Loading className={classes.loading}/>}
                {filePath && fileType && this.getViewer(filePath, fileType)}
            </div>
        );
    }
}

FileViewer.propTypes = {
    fileName: PropTypes.string,
    filePath: PropTypes.string.isRequired,
    fileType: PropTypes.string.isRequired,
    width: PropTypes.any,
    height: PropTypes.any,
    style: PropTypes.any,
    className: PropTypes.string,
    viewerProps: PropTypes.any,

    onClose: PropTypes.func,
};

export default withStyles(styles)(FileViewer);
