/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/03/30 16:12
 * @update 2019/03/30 16:12
 */

import { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

class EventTracking extends Component {

    componentDidMount() {

        let gaCode = (process && process.env && process.env.REACT_APP_GOOGLE_ANALYTICS) ? process.env.REACT_APP_GOOGLE_ANALYTICS : '';

        ReactGA.initialize(gaCode);
        this.onTracking();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {action, label, value} = this.props;

        if (label !== nextProps.label || value !== nextProps.value || action !== nextProps.action) {
            this.onTracking();
        }
    }

    onTracking() {
        let {category, action, label, value} = this.props;

        ReactGA.pageview(window.location.pathname + window.location.search);

        category = category ? category : 'Tracking';
        action = action ? action : 'Tracking Event';

        if (typeof label !== "undefined") {
            ReactGA.event({
                category: category,
                action: action,
                label: label
            });
        }
        if (typeof value !== "undefined") {
            ReactGA.event({
                category: category,
                action: action,
                value: value
            });
        }
    }

    render() {
        return null;
    }
}

EventTracking.propTypes = {
    category: PropTypes.string,
    action: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.number
};

export default EventTracking;
