import React from "react";
import {
    FormControl,
    InputLabel,
    withStyles, Input,
} from "@material-ui/core";
import propTypes from "prop-types";
import {compose} from "redux";

const styles = theme => ({
    label : {
        fontSize: "1rem",
        fontWeight: 400,
        paddingTop: '3px',
        color: theme.palette.text.main,
    },
    labelStartAdornment : {
        fontSize: "16px",
        fontWeight: 400,
        paddingTop: '3px',
        paddingBottom: '7px',
        color: theme.palette.text.main,
    },
    shrink: {
        transform: 'translate(0, 1.5px) scale(1)',
    },
    underline: {
        '&:hover:not(.Mui-disabled):before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
        },
        '&:after': {
            borderBottom: '0px'
        },
        '&:before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
        },
    },
});

class  LabelText extends React.Component {

    render() {
        const {label, value, fullWidth, color, onChange, startAdornment, style, className, allowPadding, classes, inputProps,
            multiline, rowsMax
        } = this.props;
        const propRows = rowsMax ? { rowsMax } : { rows: 4 };
        return (
            <div>
                <FormControl
                    className={className}
                    fullWidth={fullWidth}
                    style={style}>
                    {label && <InputLabel
                        shrink={true}
                        classes={{shrink: classes.shrink}}
                        focused={false}
                    >
                        {label}
                    </InputLabel>}
                    {!value || typeof value === "string" || typeof value === "number" ? (
                        <Input
                            color={color ? color : "primary"}
                            readOnly={true}
                            startAdornment={startAdornment}
                            value={value ?? ""}
                            multiline={multiline}
                            onChange={onChange}
                            style={allowPadding ? {paddingLeft: 7, paddingRight: 7} : {}}
                            {...inputProps}
                            className={
                                startAdornment
                                    ?
                                    classes.labelStartAdornment
                                    :
                                    classes.label
                            }
                            classes={{underline: classes.underline}}
                            {...propRows}
                        />
                        ) : (
                            <div className={"mgt15 MuiInput-underline " + classes.underline}
                                style={allowPadding ? {padding: '0 7px'} : {}}>
                                {typeof value === "function" ? value() : value}
                            </div>
                        )}
                </FormControl>
            </div>
        );
    }
}

LabelText.propTypes = {
    label: propTypes.node,
    value: propTypes.any,
    inputProps: propTypes.any,
    multiline: propTypes.bool,
    fullWidth: propTypes.bool,
    color: propTypes.string,
    margin: propTypes.string,
    onChange: propTypes.func,
    startAdornment: propTypes.node,
    style: propTypes.object,
    className: propTypes.string,
    allowPadding: propTypes.bool,
    rowsMax: propTypes.number,

};

export default compose(withStyles(styles, {withTheme: true}))(LabelText)
