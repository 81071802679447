/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 1/16/2020
 * @Example
 */

import React from "react";
import PropTypes from "prop-types";
import Config from "../../../config";
import { Col, FormGroup, Image } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as mainActions from "../../../redux/main/main_actions"
import * as filesActions from "../../../redux/files/file_actions"
import CircularProgress from "@material-ui/core/CircularProgress";
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import FileViewer from "../fileviewer/fileviewer";
import Modal from "../modal/modal";
import { Tooltip, Typography, withStyles } from "@material-ui/core";
import { FormHelperText } from "@material-ui/core";
import InlineSVG from "react-inlinesvg";
import Dropzone from "react-dropzone";
import CDN from "../../CDN";
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
import moment from 'moment';
const styles = {
    paper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        flexWrap: 'wrap',
        backgroundColor: 'white',
        position: 'relative',
        minHeight: 165,
        border: '1px solid #687799',
        paddingBottom: 20
    },
    border: {
        borderImageWidth: 'initial',
        borderImageOutset: 'initial',
        borderImageSource: 'url(https://aui-cdn.atlassian.com/media/border-icon.png)',
        borderImageSlice: 2,
        borderImageRepeat: 'round',
        height: '100%',
        width: '100%',
        borderWidth: 2,
        borderStyle: 'dashed',
        borderColor: 'rgb(207, 212, 219)',
        borderRadius: 3,
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '& .text-muted': {
            color: 'rgb(167, 167, 167)',
        }
    },
    dropzone: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 98,
        cursor: 'pointer'
    },
    dropzoneActive: {
        backgroundColor: "#eaeaeabf",
        zIndex: 2000,
    },
    iconAdd: {
        width: 66,
        padding: 13,
        marginBottom: 10,
        border: '2px dashed rgb(207, 212, 219)',
        borderRadius: '50%',
        '& path, polygon': {
            fill: 'rgb(207, 212, 219)'
        }
    },
    button: {
        position: 'absolute',
        top: 10,
        right: 10
    },
    itemContainer: {
        width: '100%'
    },
    items: {
        marginRight: 5,
        marginBottom: 10
    },
    attachItem: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0 0 5px #00000034',
        borderRadius: 5,
        overflow: 'hidden',
        cursor: 'pointer',
        position: 'relative',
        '& .att-wrap-icon': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '60%'
        },
        '& .att-backdrop-item': {
            display: 'none',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: 0.4,
            backgroundColor: '#484848'
        },
        '& .att-info-item': {
            display: 'none',
            position: 'absolute',
            color: '#FFFFFF',
            top: 0,
            left: 0,
            padding: 10,
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'space-between',
            '& .att-item-name': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            },
        },
        '& .att-item-detail': {
            display: 'none',
            position: 'absolute',
            bottom: 10,
            left: 0,
            width: '100%',
            padding: '0 10px'
        },
        '&:hover': {
            '& .att-backdrop-item, .att-info-item, .att-item-detail': {
                display: 'flex'
            }
        }
    },
    attachItemList: {
        width: '100%',
        height: 40,
        padding: 10,
        backgroundColor: '#F5F5F5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    attachToolbar: {
        display: 'flex',
        width: '100%',
        // flexDirection: 'row',
        // justifyContent: 'space-between',
        alignItems: 'center',
        height: 48,
        padding: 10,
        zIndex: 100,
        // borderBottom: '1px solid #cfd4db',
        marginBottom: 10
    },
    attachedInfo: {
        width: '100%',
        display: "flex",
        justifyContent: "space-around"
    },
    iconDelete: {
        color: '#FFFFFF',
        '&:hover': {
            color: 'red'
        }
    },
    IconExclamation: {
        marginRight: '8px',
        backgroundColor: '#f44336',
        borderRadius: '100%',
        color: '#fff',
        padding: '3px'
    },
    Mark: {
        transform: 'translateY(20%)'
    },
    ColorRequire: {
        color: '#f44336 !important'
    },
    BorderColorRequire: {
        // borderImageSource: 'url(../../../assets/images/border-icon-red.png) !important',
        border: '1px solid red'
    }
};
const _sizeLimit = 20000;
class Attachments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: !!props.open,
            isUploading: false,
            fileOpened: {},
            _files: [],
            showFileViewer: false,
            isDragOver: false,

            viewStyle: 'list',
            uploadStatus: {},
            voucherID: "",
        };
        this.uploadStatus = {};
        this.itemFiles = [];
        this.dropzoneRef = null;
        this.fileDeleted = [];
        this.timer = {};
        this.timerUpload = {};
        this.countFileUploading = 0;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.voucherID !== prevState.voucherID){
            return {voucherID: nextProps.voucherID, _files: []}
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { isUploading, uploadStatus } = this.state;
        if (this.props.open) {
            this.dropzoneRef.click();
        }
        if (uploadStatus && isUploading) {
            const _key = Object.keys(uploadStatus);
            let uploadFinished = true;
            for (let i = 0; i < _key.length; i++) {
                if (uploadStatus[_key[i]].isUploading) {
                    uploadFinished = false;
                    break;
                }
            };
            if (uploadFinished) {
                this.onUploading(false);
                this.onChanged(this.itemFiles);
            }
        }
    }

    openChooseFile = () => {
        if (this.dropzoneRef) {

            this.dropzoneRef.open();
        }
    };

    onDrop = async (files) => {
        const { maxSize, maxUploadOnceAllowed, maxLength, chooseWithoutSave, isAttachInfo } = this.props;
        const oldFiles = this.props.files;
        let messageSize = [];
        let messageName = [];
        const sizeLimit = maxSize ? maxSize : _sizeLimit;
        let _files = this.state._files;
        let _tmpFiles = [];
        if (!Config.profile.UserID) {
            Config.popup.show("INFO", Config.lang("DHR_Khong_the_dinh_kem"));
            return;
        }
        for (let i = 0; i < files.length; i++) {
            if (_files.length + (oldFiles ? oldFiles.length : 0) >= maxLength) {
                Config.popup.show("INFO", Config.lang("DHR_Vuot_qua_so_luong_tap_tin_cho_phep") + "<br/>" + Config.lang("DHR_So_luong_cho_phep") + ": " + maxLength);
                break;
            }
            if ((files[i].size / 1024) > Number(sizeLimit)) {
                messageSize.push(files[i].name);
            } else if (this.checkFileType(files[i].name) === false) {
                messageName.push(files[i].name)
            } else {
                const dtime = new Date().getTime() + i;
                const fileInfo = isAttachInfo ? { CreateUserID: Config.profile.UserID, CreateDate: moment().format('YYYY-MM-DD kk:mm:ss')} : {}
                const file = {
                    isNew: true,
                    key: dtime,
                    fileName: files[i].name,
                    fileSize: files[i].size,
                    fileExt: files[i].name.split('.').pop(),
                    url: files[i].type.indexOf('image') > -1 ? URL.createObjectURL(files[i]) : null,
                    _file: files[i],
                    ...fileInfo
                };
                _tmpFiles.push(file);
                _files.unshift(file);
            }
        }
        let message = "";
        if (messageSize.length > 0) {
            message += Config.lang("DHR_Tap_tin") + ": " + messageSize.join(",") + "<br>" + Config.lang("Dung_luong_File_khong_duoc_lon_hon") + " " + sizeLimit + ' KB <br>';
        }
        if (messageName.length > 0) {
            message += Config.lang("DHR_Tap_tin") + ": " + messageName.join(",") + "<br>" + Config.lang("Dinh_dang_file_khong_hop_le");
        }
        if (message) {
            Config.popup.show("INFO", message);
        }
        this.setState({
            _files: _files,
        }, () => {
            //auto upload file when select files..
            if (_tmpFiles.length > 0) {
                if (!chooseWithoutSave) {
                    this.onUploading(true);
                    const limitUploadedFile = maxUploadOnceAllowed; //allow upload once 5 files
                    this.countFileUploading = 0;
                    _tmpFiles.forEach((_f) => {
                        if (limitUploadedFile && this.countFileUploading >= limitUploadedFile) {
                            this.timerUpload[_f.key] = setInterval(() => {
                                if (this.countFileUploading < limitUploadedFile) {
                                    this.countFileUploading++;
                                    this.__uploadFiles(_f, _f.fileName);
                                    clearInterval(this.timerUpload[_f.key]);
                                }
                            }, 1000);
                        } else {
                            this.countFileUploading++;
                            this.__uploadFiles(_f, _f.fileName);
                        }
                    });
                } else {
                    //select file with not save ....
                    this.onChanged(_tmpFiles);
                }
            }
        });
    };

    __uploadFiles = (file, fileName) => {
        this.setStateUploadStatus(file, {
            success: false,
            isUploading: true,
            _valueProgress: 0,
            errorMessage: ""
        }, () => {
            setTimeout(() => {
                this.setIntervalProgressBar(file);
            }, 200);
            this._uploadFile([file._file], false, (err, data) => {
                this.countFileUploading--;
                if (err) {
                    this.setStateUploadStatus(file, {
                        success: false,
                        isUploading: false,
                        errorMessage: "Error"
                    });
                    return false;
                }
                if (data && data.paths && data.paths.length > 0) {
                    const path = data.paths[0];
                    let _uploadFile = Object.assign(file, path);
                    if (fileName) {
                        _uploadFile.fileName = fileName;
                    }
                    this.itemFiles.push(_uploadFile);
                }
                this.setStateUploadStatus(file, {
                    success: true,
                    _valueProgress: 100,
                }, () => {
                    setTimeout(() => {
                        this.setStateUploadStatus(file, {
                            isUploading: false,
                        });
                    }, 300);
                });
            });
        });
    };

    setStateUploadStatus = (file, stateFile, cb) => {
        if (file) {
            const { uploadStatus } = this.state;
            const _downLink = navigator && navigator.connection && navigator.connection.downlink ? navigator.connection.downlink : 0;
            const _fileSize = file.size / 1024;
            this.uploadStatus[file.key] = {
                ...uploadStatus[file.key],
                ...stateFile
            };
            this.setState({
                uploadStatus: {
                    ...this.uploadStatus,
                    [file.key]: {
                        ...(this.uploadStatus && this.uploadStatus[file.key] ? this.uploadStatus[file.key] : {}),
                        timeUpload: (_fileSize / _downLink),
                        ...stateFile
                    }
                }
            }, () => {
                if (cb) cb();
            });
        }
    };

    setIntervalProgressBar = (file) => {
        if (file) {
            const { uploadStatus } = this.state;
            const _downLink = navigator && navigator.connection && navigator.connection.downlink ? navigator.connection.downlink : 1;
            const _fileSize = file._file ? file._file.size / 1024 : 10;
            const _stateFile = uploadStatus && file.key && uploadStatus[file.key] ? uploadStatus[file.key] : null;
            if (_stateFile) {
                const totalTime = (_fileSize / ((_downLink / 5) * 1024)) * 1000; //milisecond..
                const _timeUpload = 100; //time upload
                const step = _timeUpload * 100 / totalTime;
                // console.log('hao_fileSize',_fileSize);
                // console.log('hao_downLink', _downLink);
                // console.log('haototalTime', totalTime);
                // console.log('hao_timeUpload', _timeUpload);
                // console.log('haostep', step);
                let _value = 0;
                this.timer = {
                    [file.key]: setInterval(() => {
                        _value += step;
                        if (_value < 100 && this.state.uploadStatus[file.key]._valueProgress < 100) {
                            this.setStateUploadStatus(file, {
                                _valueProgress: _value
                            });
                        } else {
                            this.clearTimeInterval(file.key);
                        }
                    }, _timeUpload)
                };
            }
        }
    };

    componentWillUnmount() {
        this.clearTimeInterval();
        if (this.timerUpload) {
            const _currentTimer = Object.keys(this.timerUpload);
            _currentTimer.forEach((t) => {
                if (this.timerUpload[t]) {
                    clearInterval(this.timerUpload[t]);
                }
            });
        }
    }

    clearTimeInterval = (key) => {
        if (key) {
            if (this.timer[key]) {
                clearInterval(this.timer[key]);
            }
        } else {
            const _currentTimer = Object.keys(this.timer);
            _currentTimer.forEach((t) => {
                if (this.timer[t]) {
                    clearInterval(this.timer[t]);
                }
            });
        }
    };

    checkFileType = (filename) => {
        const extension = filename.substr(filename.lastIndexOf('.')).toLowerCase();
        const allowedExtensions = ['.txt', '.jpg', '.jpeg', '.png', '.xls', '.xlsx', '.doc', '.docx', '.pdf', '.ppt', '.pptx'];
        if (extension.length > 0) {
            if (allowedExtensions.indexOf(extension) === -1) {
                return false;
            }
        }
        return true;
    };

    // onSelectFile = async () => {
    //     const {onChange, chooseWithoutSave, onFileSubmited} = this.props;
    //     const {fileNames} = this.state;
    //     const x = this.inputFiles;
    //     const arrFile = x.files;
    //     const sizeLimit = 10000;
    //     let _fileNames = fileNames;
    //     let fileIndx = fileNames.length;
    //     let messageSize = [];
    //     let messageName = [];
    //     for (let i = 0; i < arrFile.length; i++) {
    //         if ((arrFile[i].size / 1024) > Number(sizeLimit)) {
    //             messageSize.push(arrFile[i].name);
    //             // Config.popup.show("INFO", arrFile[i].name + " " + Config.lang("Dung_luong_File_khong_duoc_lon_hon") + " " + sizeLimit + ' KB');
    //         } else if (this.checkFileType(arrFile[i].name) === false) {
    //             messageName.push(arrFile[i].name)
    //             // Config.popup.show("INFO", Config.lang('Dinh_dang_file_khong_hop_le'));
    //         } else {
    //             _fileNames.push({FileName: arrFile[i].name});
    //             this.itemFiles[fileIndx] = arrFile[i];
    //             fileIndx++;
    //         }
    //     }
    //     let message = "";
    //     if (messageSize.length > 0) {
    //         message += Config.lang("DHR_Tap_tin") + ": " +  messageSize.join(",") + "<br>" + Config.lang("Dung_luong_File_khong_duoc_lon_hon") + " " + sizeLimit + ' KB <br>';
    //     }
    //     if (messageName.length > 0) {
    //         message += Config.lang("DHR_Tap_tin") + ": " +  messageName.join(",") + "<br>" + Config.lang("Dinh_dang_file_khong_hop_le");
    //     }
    //     if (message) {
    //         Config.popup.show("INFO", message);
    //     }
    //     this.setState({fileNames: _fileNames});
    //
    //     if (onChange) onChange({files: this.itemFiles, deletedFile: this.fileDeleted});
    //
    //     if (onFileSubmited) {
    //         if (chooseWithoutSave) {
    //             this.onSubmited(this.itemFiles);
    //         } else {
    //             this.onUploading(true);
    //             const upload = await this._uploadFile(this.itemFiles, 'multiple');
    //             this.onSubmited(upload && upload.data ? upload.data : null);
    //         }
    //     }
    // };

    _uploadFile = (files, isAsync, cb) => {
        // return await FileUploadSync(files, mode, this.tokenCDN);
        if (isAsync) {
            return CDN.uploadFileSync(files);
        } else {
            return CDN.uploadFile(files, null, cb);
        }
    };

    _removeFileCDN = (params) => {
        CDN.removeFile(params);
    };

    onUploading = (state) => {
        const { onUploading } = this.props;
        if (onUploading) onUploading(state);
        this.setState({ isUploading: state });
    };

    // onSubmited = (files) => {
    //     const {onChange} = this.props;
    //     this.onUploading(false);
    //     if (onChange) onChange({uploadedfiles: files ? files : [], deletedFiles: this.fileDeleted});
    // };

    onAttachment = () => {
        if (this.dropzoneRef) {
            this.dropzoneRef.open();
        }
    };

    onReset = () => {
        this.setState({
            _files: [],

            uploadStatus: {},
            voucherID: "",
        });
        this.uploadStatus = {};
        this.itemFiles = [];
        this.dropzoneRef = null;
        this.fileDeleted = [];
        this.timer = {};
        this.timerUpload = {};
        this.countFileUploading = 0;
    };

    onRemoveFile = (file) => {
        if (!file) return false;
        if (!file.isNew) {
            this.fileDeleted.push(file);
        } else {
            if (file.key) {
                let { _files } = this.state;
                _files = _files.filter((f) => {
                    return f.key !== file.key;
                });
                this.itemFiles = this.itemFiles.filter((f) => {
                    return f.key !== file.key;
                });
                this.setState({
                    _files: _files
                });
            }
        }
        this.onChanged(null, true);
    };

    onChanged = (files, isDeleted) => {
        const { onChanged, onDeleted } = this.props;
        if (isDeleted && onDeleted) onDeleted({ deletedFiles: this.fileDeleted });
        if (onChanged) onChanged({ uploadedFiles: files ? files : this.itemFiles, deletedFiles: this.fileDeleted });
    };

    onViewFile = (data) => {
        if (data.url) {
            this.setState({
                fileOpened: {
                    FileName: data.fileName ? data.fileName : "",
                    FileExt: data.fileExt ? data.fileExt : Config.helpers.getExtFile(data.fileName),
                    URL: data.url ? (data.url.indexOf('http') < 0 ? Config.getCDNPath() + data.url : data.url) : ""
                },
                showFileViewer: true,
            });
        }
    };

    onCloseViewFile = () => {
        this.setState({ showFileViewer: false })
    };

    onChangeView = () => {
        this.setState({
            viewStyle: this.state.viewStyle === 'list' ? 'module' : 'list'
        })
    };

    _getIconFile = (fileName) => {
        let icon = require('../../../assets/images/files/file.svg');
        try {
            icon = require('../../../assets/images/files/' + fileName);
        } catch (e) {

        }
        return icon;
    };

    onDragOver = () => {
        this.setState({ isDragOver: true });
    };

    render() {
        const { classes, disabled, alwayShowArea, files, uploading, hidden, error, required, isAttachInfo, isLocal } = this.props;
        const { isDragOver, viewStyle, _files, showFileViewer, fileOpened, uploadStatus } = this.state;
        const convertFiles = Config.helpers.getFileInfomations(files);
        let dataFiles = convertFiles ? _files.concat(convertFiles) : _files;
        const extAllowView = ['jpg', 'jpeg', 'png', 'bmp', 'svg', 'gif']
        return (
            <React.Fragment>
                <Modal style={{ zIndex: 1504 }} open={showFileViewer} maxWidth={"lg"} fullWidth={true}
                    paperProps={{ style: { height: '100%' } }}
                    title={Config.lang('DHR_Xem_tap_tin')}
                       type={"file"}
                       download={{
                           filePath: fileOpened.URL || "",
                           FileName: fileOpened.FileName,
                           fileType: fileOpened.FileExt
                       }}
                    onClose={this.onCloseViewFile}>
                    <FileViewer isLocal={isLocal} fileName={fileOpened.FileName} filePath={fileOpened.URL} fileType={fileOpened.FileExt} height={'100%'} onClose={this.onCloseViewFile} />
                </Modal>
                <FormGroup>
                    <Paper style={{ display: (typeof alwayShowArea === "undefined" || alwayShowArea || _files.length > 0) && !hidden ? 'flex' : 'none' }}
                        elevation={0}
                        className={classes.paper + " " + (error && _.isEmpty(_files) ? classes.BorderColorRequire : "")}
                    >
                        <div className={classes.attachToolbar}>
                            <Typography style={{fontSize: 12}} className={"text-uppercase " + (error && _.isEmpty(_files) ? classes.ColorRequire : "")}>{Config.lang("DHR_Dinh_kem")}</Typography>
                            {required ? <span style={{ paddingLeft: '4px' }} className={classes.ColorRequire}>*</span> : ""}
                            <div style={{ marginLeft: 'auto' }}>
                                {error && _.isEmpty(_files) ? <span className={classes.IconExclamation}><PriorityHighIcon className={classes.Mark} fontSize="small" /></span> : ""}
                                <IconButton aria-label="order" size={"small"} onClick={this.onChangeView}>
                                    {viewStyle === "list" ? (<ViewModuleIcon />) : (<ViewListIcon />)}
                                </IconButton>
                            </div>
                        </div>
                        <div style={{ width: '100%', height: '100%' }}>
                            {/*<div className={classes.border + " " + (error && _.isEmpty(_files) ? classes.BorderColorRequire : "")} />*/}
                            <span className={"display_col align-center valign-middle"} style={{ width: '100%', height: '100%' }}>
                                <Dropzone ref={ref => this.dropzoneRef = ref}
                                    onDragOver={this.onDragOver}
                                    style={{ ...(isDragOver ? { zIndex: 1000 } : {}) }}
                                    disabled={disabled}
                                    accept={".txt, image/jpg, image/jpeg, image/png, .xls, .xlsx, .doc, .docx, application/pdf, .ppt, .pptx"}
                                    onDrop={this.onDrop}>
                                    {({ getRootProps, getInputProps, isDragActive }) => (
                                        <>
                                            <div {...getRootProps({ className: classes.dropzone + (isDragActive ? " " + classes.dropzoneActive : "") })}>
                                                <input {...getInputProps()} />
                                            </div>
                                            {dataFiles && dataFiles.length < 1 &&
                                                <React.Fragment>
                                                    <InlineSVG src={require('../../../assets/images/general/add_circle.svg')} className={classes.iconAdd} />
                                                    <span style={{ fontStyle: 'italic', fontSize: 12 }} >{Config.lang("DHR_Keo_va_tha_tap_tin_dinh_kem_vao_day")}</span>
                                                </React.Fragment>
                                            }
                                        </>
                                    )}
                                </Dropzone>
                            </span>
                        </div>
                        {viewStyle === "module" && <div style={{ display: 'block', width: '100%' }}>
                            {dataFiles.map((file, idx) => {
                                const {UserID} = Config.profile;
                                const ext = file && file.fileExt ? file.fileExt : Config.helpers.getExtFile(file.fileName);
                                const preview = file && file.url && file.url.indexOf('http') > -1 ? file.url : Config.getCDNPath() + file.url;
                                const _uploadStatus = uploadStatus && file.key && uploadStatus[file.key] ? uploadStatus[file.key] : null;
                                let allowRmAttachment = false;
                                let createUserID = _.get(file, "CreateUserID", "");
                                let isAttachedInfo = createUserID !== "";
                                if(isAttachedInfo) {
                                    allowRmAttachment = createUserID === UserID;
                                }
                                return (
                                    <Col key={idx} xs={6} sm={4} md={3} lg={2} className={"mgb10"} style={{ height: 165, padding: '0 10px', zIndex: 99 }}>
                                        <div className={classes.attachItem}>
                                            <div style={{ width: '100%', height: '100%' }}
                                                className={"display_row valign-middle align-center"}
                                                onClick={() => this.onViewFile(file)}>
                                                {extAllowView.indexOf(ext) > -1 && file.url ? (
                                                    <Image key={idx} src={preview}
                                                        style={{ maxWidth: '100%', height: '100%', objectFit: 'contain' }}
                                                    />
                                                ) : (
                                                        <div className={classes.attachItem}>
                                                            <div className={'att-wrap-icon'}>
                                                                <Image style={{ height: 50 }} src={this._getIconFile(ext + '.svg')} />
                                                            </div>
                                                            <div style={{ textAlign: 'center', fontSize: 12, maxHeight: '42%', padding: '0 10px', wordBreak: 'break-all' }}>
                                                                {file.fileName}
                                                            </div>
                                                        </div>
                                                    )}
                                                <div className={"att-backdrop-item"} style={{ ...(_uploadStatus && _uploadStatus.isUploading ? { display: 'flex' } : {}) }} />
                                                <div className={"att-info-item"}>
                                                    <Tooltip title={file.fileName} aria-label={file.fileName}>
                                                        <div className={"att-item-name"}>
                                                            {file.fileName}
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            {(!_uploadStatus || (_uploadStatus && !_uploadStatus.isUploading)) && <div className={"display_row align-center align-between att-item-detail"}>
                                                <div className={"att-item-type"}>
                                                    <Image style={{ height: 15 }} src={this._getIconFile(ext + '.svg')} />
                                                </div>
                                                <div className={"att-item-remove"}>
                                                    <IconButton aria-label="delete" size="small" color={"secondary"} disabled={!_.get(file, "IsAttached", true) || (isAttachInfo && !allowRmAttachment)} onClick={() => this.onRemoveFile(file)}>
                                                        <DeleteIcon className={classes.iconDelete} fontSize="small" />
                                                    </IconButton>
                                                </div>
                                            </div>}
                                            {_uploadStatus && _uploadStatus.isUploading && <div className={"display_row align-center align-between pdl10 pdr10"} style={{ position: 'absolute', bottom: 10, left: 0, width: '100%' }}>
                                                <LinearProgress variant="determinate" value={_uploadStatus._valueProgress} style={{ width: '100%', height: 10, borderRadius: 20 }} />
                                            </div>}
                                        </div>
                                    </Col>
                                )
                            })}
                        </div>}
                        {viewStyle === "list" && <div style={{ display: 'block', width: '100%' }}>
                            {dataFiles.map((file, idx) => {
                                const { UserNameU, UserID} = Config.profile;
                                const _uploadStatus = uploadStatus && file.key && uploadStatus[file.key] ? uploadStatus[file.key] : null;
                                const getCreateDate = _.get(file, "CreateDate", "");
                                let employeeName = "";
                                let separator = "";
                                let allowRmAttachment = false;
                                let createDate = moment(getCreateDate).isValid()  ? moment.utc(getCreateDate).format("DD/MM/YYYY kk:mm:ss") : getCreateDate;
                                let createUserID = _.get(file, "CreateUserID", "");
                                let isAttachedInfo = createUserID !== "" || createDate !== "";

                                if(isAttachedInfo) {
                                    employeeName = isAttachedInfo && _.get(file, "UserName", UserNameU);
                                    separator = employeeName && createDate ? " - " : separator;
                                    allowRmAttachment = createUserID === UserID;
                                }
                                return (
                                    <Col key={idx} xs={12} sm={12} md={8} lg={8} className={"mgb15"} style={{ height: 40, zIndex: 99, width: isAttachedInfo ? "100%" : "" }}>
                                        <div className={classes.attachItemList}>
                                            <span style={{ width: isAttachedInfo ? "93%" : 'calc(100% - 200px)', height: '100%', justifyContent: isAttachedInfo ? "space-between" : "normal" }}
                                                className={"display_row"}>
                                                {/* eslint-disable */}
                                                <Tooltip title={file.fileName} aria-label={file.fileName}>
                                                    {file.url ? (
                                                        <a style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: "hidden" }}
                                                            onClick={() => this.onViewFile(file)}>{file.fileName}</a>
                                                    ) : (
                                                            <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: "hidden" }}>{file.fileName}</span>
                                                        )}
                                                </Tooltip>
                                                {isAttachedInfo ?
                                                    <Tooltip title={`${employeeName}${separator}${createDate}`} aria-label={employeeName}>
                                                        <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: "hidden" }}>
                                                            <span>{employeeName}</span>{separator}<span>{createDate}</span>
                                                        </div>
                                                    </Tooltip> : <span>&nbsp;{file.fileSize ? '(' + (file.fileSize / 1024 / 1024).toFixed(1) + 'MB)' : ""}</span>}
                                                {/* eslint-enable */}
                                            </span>
                                            {_uploadStatus && _uploadStatus.isUploading && <div style={{ width: '30%', minWidth: 150 }}>
                                                <LinearProgress variant="determinate" value={_uploadStatus._valueProgress} style={{ width: '100%', height: 10, borderRadius: 20 }} />
                                            </div>}
                                            {(!_uploadStatus || (_uploadStatus && !_uploadStatus.isUploading)) && <IconButton disabled={!_.get(file, "IsAttached", true) || (isAttachInfo && !allowRmAttachment)} aria-label="order" size={"small"} onClick={() => this.onRemoveFile(file)}>
                                                <CloseIcon />
                                            </IconButton>}
                                        </div>
                                    </Col>
                                )
                            })}
                        </div>}
                        {(uploading) && <div className="display_row align-center valign-middle loading">
                            <CircularProgress />
                        </div>}
                        {error && _.isEmpty(_files) ? <FormHelperText className={classes.ColorRequire}>{error}</FormHelperText> : ""}
                    </Paper>
                </FormGroup>
            </React.Fragment>
        );
    }
}

Attachments.defaultProps = {
    maxUploadOnceAllowed: 0
};
Attachments.propTypes = {
    uploading: PropTypes.bool,
    chooseWithoutSave: PropTypes.bool,
    disabled: PropTypes.bool,
    files: PropTypes.any, //[{URL: "", FileName: "aaaa.xls", FileExt: "xls"}]
    maxLength: PropTypes.number,
    alwayShowArea: PropTypes.bool,
    maxSize: PropTypes.number,
    hidden: PropTypes.bool,
    maxUploadOnceAllowed: PropTypes.number,
    isAttachInfo: PropTypes.bool,
    isLocal: PropTypes.bool,

    onUploading: PropTypes.func,
    onChanged: PropTypes.func,
    onDeleted: PropTypes.func,
    onFileSubmited: PropTypes.func,
};

export default compose(connect(null,
    (dispatch) => ({
        mainActions: bindActionCreators(mainActions, dispatch),
        filesActions: bindActionCreators(filesActions, dispatch),
    }), null, { forwardRef: true }
), withStyles(styles))(Attachments);
