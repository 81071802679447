/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 8/17/2020
 * @Example
 */

export default {
    edit: {
        color: "default",
        colorHover: "info",
        width: 20,
        height: 20,
    },
    delete: {
        color: "default",
        colorHover: "warning",
        width: 20,
        height: 20,
    },
    view: {
        color: "default",
        colorHover: "info",
        width: 20,
        height: 20
    },
    approval: {
        color: "default",
        colorHover: "success",
        width: 26,
        height: 26
    },
    notapproval: {
        src: require("./_icons/cancel.svg"),
        color: "default",
        colorHover: "danger",
        width: 26,
        height: 26
    },
};

// export default [
//     {
//         name: "add",
//         src: require("./icons/add.svg"),
//     },
//     {
//         name: "edit",
//         src: require("./icons/edit.svg"),
//         color: "default",
//         colorHover: "info",
//         width: 20,
//         height: 20
//     },
//     {
//         name: "delete",
//         src: require("./icons/delete.svg"),
//         color: "default",
//         colorHover: "warning",
//         width: 20,
//         height: 20
//     },
//     {
//         name: "view",
//         src: require("./icons/view.svg"),
//         color: "default",
//         colorHover: "info",
//         width: 20,
//         height: 20
//     },
//     {
//         name: "approval",
//         src: require("./icons/approval.svg"),
//         color: "default",
//         colorHover: "success"
//     },
//     {
//         name: "notapproval",
//         src: require("./icons/cancel.svg"),
//         color: "default",
//         colorHover: "danger"
//     },
//     {
//         name: "check",
//         icons: require("./icons/approval.svg"),
//     },
//     {
//         name: "add_user",
//         icons: require("./icons/add_user.svg"),
//     },
//     {
//         name: "back_filled",
//         icons: require("./icons/back_filled.svg"),
//     },
//     {
//         name: "biggroup",
//         icons: require("./icons/biggroup.svg"),
//     },
//     {
//         name: "book",
//         icons: require("./icons/book.svg"),
//     },
//     {
//         name: "cancel",
//         icons: require("./icons/cancel.svg"),
//     },
//     {
//         name: "cancel_filled",
//         icons: require("./icons/cancel_filled.svg"),
//     },
// ]

