/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/14/2019
 * @Example
 */

import React, {PureComponent} from 'react';

import PropTypes from "prop-types";
import {DropDownBox, TreeView} from "devextreme-react";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import * as w00f0010Actions from "../../../redux/W0X/W00F0010/W00F0010_actions";
import * as generalActions from "../../../redux/general/general_actions";
import Config from "../../../config";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

class DropdownTreeview extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            shrink: props.value && props.value.length > 0,
            opened: false,
            treeBoxValue: props.value,
            dataSource: [],
            disableNodes: new Set(),
            selectFirstNode: true
        };
    }

    loadDataOrgChart = () => {
        this.props.generalActions.getOrgCharts({}, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const dataSource = this._generateDataOrg(data, "OrgChartID", "OrgChartParentID");
                this.setState({ dataSource: dataSource });
            }
        });
    };

    componentDidMount() {
        const { typeDefault, value, selectionMode } = this.props;
        const { dataSource } = this.state;

        if (typeDefault === 'organizational') {
            this.loadDataOrgChart();
            const newDisableNodes = new Set();
            if (selectionMode === 'multiple' && value?.length > 0) {
                value.forEach(item => {
                    const parentArr = this.getParentIDs(dataSource, item);
                    const childArr = this.getChildIDs(dataSource, item);
                    parentArr.forEach(i => newDisableNodes.add(i));
                    childArr.forEach(i => newDisableNodes.add(i));
                });
                this.setState({ disableNodes: newDisableNodes });
            }
        }
    }

    syncTreeViewSelection = (e) => {
        const {selectionMode, onValueChanged} = this.props;
        let treeView = (e.component.selectItem && e.component) || (this.treeView && this.treeView.instance);

        if (treeView) {
            if (e.value === null) {
                this.setState({treeBoxValue: selectionMode === 'single' ? '' : [], disableNodes: new Set()}, () => {
                    treeView.unselectAll();
                });
            } else {
                let values = e.value || this.state.treeBoxValue;
                // values = !Array.isArray(values) ? [values] : values;
                // values && values.forEach(function(value) {
                treeView.selectItem(values);
                // });
            }

        }

        if (onValueChanged) {
            if (onValueChanged) onValueChanged(e, e.value ? e.value : (selectionMode === "multiple" ? [] : null));
        }

        if (e.value !== undefined) {
            this.setState({
                treeBoxValue: e.value
            });
        }
    };

    treeViewSelectionChanged = (e) => {
        const { selectionMode, closeWhenSelected, onValueChanged, disabledParentAndChild } = this.props;
        const { dataSource } = this.state;
        
        let newDisableNodes = new Set();
        const selectedNodes = e.component.getSelectedNodes();

        if (selectedNodes && selectedNodes.length > 0) {
            selectedNodes.forEach(node => {
                if (node.parent) {
                    newDisableNodes.add(node.parent.key);
                    const parentArr = this.getParentIDs(dataSource, node.parent.key);
                    parentArr.forEach(i => {
                        newDisableNodes.add(i);
                    });
                }
                const childNodes = this.getChildNodes(node);
                childNodes.forEach(i => {
                    newDisableNodes.add(i);
                });
            })
        }

        this.setState({
            selectFirstNode: false,
            disableNodes: newDisableNodes,
            shrink: e.component.getSelectedNodesKeys().length > 0,
            treeBoxValue: e.component.getSelectedNodesKeys()
        }, () => {
            const _closeWhenSelected = typeof closeWhenSelected === "undefined" ? true : closeWhenSelected;
            if ((!selectionMode || selectionMode === "single") && _closeWhenSelected) {
                this.dropdownBox.instance.close();
            }
            if (disabledParentAndChild) {
                const seletedNode = e?.node?.key ?? e?.itemElement ?? e?.itemData;
                setTimeout(() => {
                    e.component.scrollToItem(seletedNode);
                }, 0);
            }
        });
        if (e.event && onValueChanged) {
            if (onValueChanged) onValueChanged(e, e.component.getSelectedNodesKeys());
        }
    };

    getParentIDs = (dataSource, id) => {
        let arr = [];
        const crt =  dataSource.find(i => i.OrgChartID === id);
        if (crt?.OrgChartParentID) arr = [crt?.OrgChartParentID, ...this.getParentIDs(dataSource, crt?.OrgChartParentID)];
        return arr;
    };

    getChildIDs = (dataSource, id) => {
        return dataSource.reduce((arr, i) => arr.concat(i.OrgChartParentID === id ? [i.OrgChartID, ...this.getChildIDs(dataSource, i.OrgChartID)] : []), []);
    };

    getChildNodes = (node) => {
        let arr = [];
        if (node.children.length > 0) {
            node.children.forEach(i => {
                arr = arr.concat(i.key, this.getChildNodes(i));
            });
        }
        return arr;
    };

    treeView_onContentReady = (e) => {
        const { selectionMode, disabledParentAndChild } = this.props;
        const { treeBoxValue, selectFirstNode } = this.state;
        if (selectionMode === 'single') {
            e.component.selectItem(treeBoxValue);
        }
        else if (selectionMode === 'multiple' && disabledParentAndChild && selectFirstNode) {
            if(treeBoxValue && treeBoxValue.length > 0) {
                e.component.scrollToItem(treeBoxValue[0]);
            }
        }
    };

    _generateDataOrg = (dataSource, valueExpr, parentIdExpr) => {
        return dataSource.map((e) => {
            if (e[valueExpr] && e[parentIdExpr] && e[valueExpr] === e[parentIdExpr]) {
                delete e[parentIdExpr];
                e.expanded = true;
            } else if (!e[parentIdExpr]) {
                e.expanded = true;
            }
            return e;
        });
    };

    onOpened = () => {
        this.setState({
            opened: true,
            selectFirstNode: true
        });
    };

    onClosed = () => {
        this.setState({
            opened: false
        });
    };

    onFocusIn = () => {
        this.setState({
            shrink: !!this.state.treeBoxValue,
        });
    };

    onFocusOut = () => {
        this.setState({
            shrink: !!this.state.treeBoxValue,
        });
    };

    _mapDataSelected = (dataSource, valueExpr) => {
        if (!dataSource) return dataSource;
        const {treeBoxValue} = this.state;
        return dataSource.map(d => {
            if (treeBoxValue?.includes(d[valueExpr])) {
                d.selected = true;
            }
            return d;
        });
    };

    disableParentAndChild = (dataSource) => {
        if (!dataSource) return dataSource;

        const { disableNodes } = this.state;
        const disableNodesArr = [...disableNodes];

        if (dataSource?.length > 0) {
            dataSource.forEach(i => {
                if (disableNodesArr?.length > 0 && disableNodesArr.includes(i.OrgChartID)) {
                    i.Disabled = 1;
                    i.expanded = 1;
                }
            });
        }

        return dataSource;
    };


    render() {
        const {typeDefault, searchEnabled,searchEditorOptions, label,
            showClearButton, stylingMode, height, selectionMode, showCheckBoxesMode, selectNodesRecursive, selectByClick,
            className, dropdownProps, listProps, InputLabelProps, disabledParentAndChild
        } = this.props;
        let {keyExpr, valueExpr, displayExpr, parentIdExpr, disabledExpr, placeholder, dataSource} = this.props;
        const {opened, shrink, treeBoxValue, dataSource : _dataSource} = this.state;

        if (typeDefault === 'organizational') {
            valueExpr = valueExpr ? valueExpr : "OrgChartID";
            keyExpr = keyExpr ? keyExpr : valueExpr;
            displayExpr = displayExpr ? displayExpr : "OrgName";
            parentIdExpr = parentIdExpr ? parentIdExpr : "OrgChartParentID";
            disabledExpr = disabledExpr ? disabledExpr : "Disabled";
            dataSource = _dataSource;
        }
        let handleDataSource = this._mapDataSelected(JSON.parse(JSON.stringify(dataSource)), valueExpr);
        
        if (selectionMode === 'multiple' && disabledParentAndChild) {
            handleDataSource = this.disableParentAndChild([...handleDataSource]);
        }

        return (
            <React.Fragment>
                <FormControl className={className} variant={"outlined"} margin={"dense"} fullWidth={true}>
                    <InputLabel
                        className={opened && shrink ? "Mui-focused" : ""}
                        shrink={!!shrink}
                        style={{
                            backgroundColor: '#fff',
                            padding: '0 4px',
                            left: '-2px'
                        }}
                        {...InputLabelProps}
                        >{label}</InputLabel>
                    <DropDownBox
                        ref={ref => this.dropdownBox = ref}
                        value={treeBoxValue}
                        valueExpr={valueExpr}
                        displayExpr={displayExpr}
                        showClearButton={showClearButton}
                        stylingMode={stylingMode}
                        height={height}
                        placeholder={placeholder}
                        dataSource={handleDataSource}
                        onValueChanged={this.syncTreeViewSelection}
                        onFocusIn={this.onFocusIn}
                        onFocusOut={this.onFocusOut}
                        onOpened={this.onOpened}
                        onClosed={this.onClosed}
                        {...dropdownProps}
                        contentRender={() => {
                            return (
                                <TreeView dataSource={handleDataSource}
                                          ref={(ref) => this.treeView = ref}
                                          dataStructure={'plain'}
                                          keyExpr={keyExpr}
                                          parentIdExpr={parentIdExpr}
                                          selectionMode={selectionMode}
                                          showCheckBoxesMode={showCheckBoxesMode ? showCheckBoxesMode : (selectionMode === 'multiple' ? 'normal' : 'none')}
                                          selectNodesRecursive={selectNodesRecursive}
                                          searchEditorOptions={searchEditorOptions}
                                          searchEnabled={searchEnabled}
                                          noDataText={Config.lang("DHR_Khong_co_du_lieu")}
                                          displayExpr={displayExpr}
                                          disabledExpr={disabledExpr}
                                          selectByClick={selectByClick}
                                          onContentReady={this.treeView_onContentReady}
                                          onItemSelectionChanged={this.treeViewSelectionChanged}
                                          {...listProps}
                                />
                            );
                        }}
                    />
                </FormControl>
            </React.Fragment>
        )
    }
}

DropdownTreeview.defaultProps = {
    selectNodesRecursive: true,
    searchEditorOptions: {stylingMode: "outlined"},
    searchEnabled: true,
    selectionMode: "single",
    selectByClick: true,
    stylingMode: "outlined"
};
DropdownTreeview.propTypes = {
    dataSource: PropTypes.any,
    label: PropTypes.string,
    value: PropTypes.any,
    className: PropTypes.string,
    dropdownProps: PropTypes.any,
    listProps: PropTypes.any,
    InputLabelProps: PropTypes.any,
    keyExpr: PropTypes.string,
    valueExpr: PropTypes.string,
    displayExpr: PropTypes.string,
    parentIdExpr: PropTypes.string,
    placeholder: PropTypes.string,
    showClearButton: PropTypes.bool,
    stylingMode: PropTypes.string,
    height: PropTypes.any,
    selectionMode: PropTypes.string,
    showCheckBoxesMode: PropTypes.string,
    selectNodesRecursive: PropTypes.bool,
    selectByClick: PropTypes.bool,
    searchEnabled: PropTypes.bool,
    searchEditorOptions: PropTypes.any,
    closeWhenSelected: PropTypes.bool,
    typeDefault: PropTypes.string, //'organizational', ....

    onValueChanged: PropTypes.func,
    disabledExpr: PropTypes.string,
    disabledParentAndChild: PropTypes.bool
};

export default compose(connect((state) => ({
        getOrgCharts: state.general.getOrgCharts,
    }),
    (dispatch) => ({
        w00f0010Actions: bindActionCreators(w00f0010Actions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch),
    })
))(DropdownTreeview);