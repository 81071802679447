/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/23/2019
 * @Example
 */

import React from "react";
import {FormGroup, Row, Col} from "react-bootstrap";
import Config from "../../config";
import * as w09f0001Actions from "../../redux/W0X/W09F0001/W09F0001_actions";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import {TextField} from "../common/form-material";
import ButtonGeneral from "../common/button/button-general";
import ButtonCustom from "../common/button/button-custom";
import {Column} from "devextreme-react/data-grid";
import GridContainer from "../grid-container/grid-container";
import ActionToolbar from "../common/toolbar/action-toolbar";
import CDN from "../CDN";
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";
import GridActionBar from "../action-bar/grid-action-bar";
import FileViewer from "../common/fileviewer/fileviewer";
import Modal from "../common/modal/modal";

class W09F0001 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isUpdate: false,
            isSave: false,
            currentFileName: '',
            txtDescription: '',
            showFileViewer: false,

            dataAttachments: [],
            total: 0,
            openedFile: {
                filePath: '',
                fileType: ''
            }
        };
        this.filter = {
            limit: 10,
            skip: 0
        };
        this.files = [];
        this.inputFiles = null;
        this.limitSize = 5120;
        this.tmpFiles = [];
        this.deleteAttachment = [];
    }

    getInfo = () => {
        const {location} = this.props;
        if (location && location.state) {
            return {
                EmployeeID: location.state.EmployeeID
            }
        } else {
            return {
                EmployeeID: Config.getHREmployeeID()
            }
        }
    };

    loadDataAttachments = () => {
        const {data} = this.props;
        const param = {
            DivisionID: Config.getDivisionID(),
            CompanyID: Config.getDatabaseName(),
            TableName: data && data.TableName ? data.TableName : '',
            FormID: data && data.FormID ? data.FormID : '',
            KeyID: data && data.KeyID ? data.KeyID : '',
            limit: this.filter.limit,
            skip: this.filter.skip
        };
        this.props.w09f0001Actions.getDataAttachments(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                this.setState({
                    dataAttachments: data && data.rows ? data.rows : [],
                    total: data && data.total ? data.total : 0
                });
            }
        });
    };

    componentDidMount() {
        CDN.getToken();
        this.loadDataAttachments();
    }

    onChoose = () => {
        this.inputFiles.click();
    };

    handleDesChange = (e) => {
        if (e) {
            this.setState({
                txtDescription: e.target.value
            });
        }
    };

    checkFileType = (filename) => {
        const extension = filename.substr(filename.lastIndexOf('.')).toLowerCase();
        const allowedExtensions = ['.txt', '.jpg', '.jpeg', '.png', '.doc', '.docx', '.pdf'];
        if (extension.length > 0) {
            if (allowedExtensions.indexOf(extension) === -1) {
                return false;
            }
        }
        return true;
    };

    onSelectFile = () => {
        const x = this.inputFiles;
        const arrFile = x.files;
        for (let i = 0; i < arrFile.length; i++) {
            if ((arrFile[i].size / 1024) > Number(this.limitSize)) {
                Config.popup.show("INFO", Config.lang("Dung_luong_File_khong_duoc_lon_hon") + " " + this.limitSize + ' KB');
            } else if (this.checkFileType(arrFile[i].name) === false) {
                Config.popup.show("INFO", Config.lang('Dinh_dang_file_khong_hop_le'));
            } else {
                this.files.push(arrFile[i]);
                this.setState({
                    isUpdate: true,
                    currentFileName: arrFile[i].name
                });
            }
        }
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadDataAttachments();
    };

    onChangePerPage = (per) => {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadDataAttachments();
    };

    onContinue = () => {
        this.files = [];
        this.setState({
            isUpdate: false,
            isSave: false,
            currentFileName: "",
            txtDescription: ""
        });
    };

    onSaveContinue = () => {
        //delete attachment..
        this.setState({isUpdate: false});
        if (this.deleteAttachment.length > 0) {
            this._deleteData(() => {
                this.deleteAttachment = [];
                this._saveData(true);
            });
        } else {
            this._saveData(true);
        }
    };

    onSave = () => {
        this.setState({isUpdate: false});
        if (this.deleteAttachment.length > 0) {
            this._deleteData(() => {
                this.deleteAttachment = [];
                this._saveData();
            });
        } else {
            this._saveData();
        }
    };

    _uploadFile = async (files) => {
        return await CDN.uploadFileSync(files);
    };

    _deleteData = (cb) => {
        // this.deleteAttachment.forEach((e) => {
        //     CDN.removeFile({path: e.path});
        // });
        const listDeleteAtts = this.deleteAttachment.map((e) => {
            return {AttachmentID: e.AttachmentID};
        });
        const paramDelete = {
            data: JSON.stringify(listDeleteAtts)
        };
        this.props.w09f0001Actions.deleteAttachment(paramDelete, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (cb) cb();
        });
    };

    _saveData = async (toContinue) => {
        const {data, onSave} = this.props;
        if (this.files.length > 0) {
            const dataUpload = await this._uploadFile(this.files);
            if (dataUpload && dataUpload.paths) {
                const {txtDescription} = this.state;
                let listAttachments = [];
                dataUpload.paths.forEach((e) => {
                    listAttachments.push({
                        DescriptionU: txtDescription,
                        URL: e.url,
                        FileName: e.fileName,
                        FileSize: e.fileSize,
                        fileExt: Config.helpers.getExtFile(e.fileName)
                    });
                });
                const params = {
                    params: JSON.stringify({
                        DivisionID: Config.getDivisionID(),
                        TableName: data && data.TableName ? data.TableName : '',
                        FormID: data && data.FormID ? data.FormID : '',
                        KeyID: data && data.KeyID ? data.KeyID : '',
                        arrAttachment: listAttachments
                    })
                };

                this.props.w09f0001Actions.saveAttachment(params, (error) => {
                    if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
                    listAttachments.forEach((a) => {
                        this.tmpFiles.push({
                            fileName: a.fileName,
                            size: a.fileSize,
                            url: dataUpload.domain + '/' + a.url
                        });
                    });
                    if (onSave) onSave({files: this.tmpFiles});
                    if (toContinue) {
                        this.files = [];
                        this.setState({
                            isUpdate: false,
                            isSave: false,
                            currentFileName: "",
                            txtDescription: ""
                        }, () => {
                            this.loadDataAttachments();
                        });
                    } else {
                        this.files = [];
                        this.setState({
                            isUpdate: false,
                            isSave: true
                        }, () => {
                            this.loadDataAttachments();
                        });
                    }

                    Config.notify.show('success', Config.lang("DHR_Luu_thanh_cong"), 2000);
                });
            }
        } else {
            if (toContinue) {
                this.setState({
                    isUpdate: false,
                    isSave: false,
                    currentFileName: "",
                    txtDescription: ""
                }, () => {
                    this.loadDataAttachments();
                });
            } else {
                this.setState({
                    isUpdate: false,
                    isSave: true
                }, () => {
                    this.loadDataAttachments();
                });
            }
            Config.notify.show('success', Config.lang("DHR_Luu_thanh_cong"), 2000);
        }
    };

    onDelete = (e) => {
        Config.popup.show("YES_NO", Config.lang('DHR_Ban_co_chac_chan_muon_xoa_du_lieu_nay'), () => {
            const {dataAttachments} = this.state;
            const {data} = e.row;
            let grid = [...dataAttachments];
            this.deleteAttachment.push({AttachmentID: data.AttachmentID, path: data.URL});
            grid.splice(e.rowIndex, 1);
            this.setState({
                isUpdate: true,
                isSave: false,
                dataAttachments: grid
            });
        })
    };

    openViewFile = (rowData) => {
        if (rowData) {
            try {
                const fileExt = Config.helpers.getExtFile(rowData.FileName);
                this.setState({
                    openedFile: {
                        fileName: rowData.FileName,
                        filePath: Config.getCDNPath() + rowData.URL,
                        fileType: fileExt ? fileExt : ''
                    },
                }, () => {
                    this.setState({showFileViewer: true})
                });
            } catch (e) {
                return false;
            }
        }
    };

    onCloseViewFile = () => {
        this.setState({
            showFileViewer: false
        });
    };

    renderAction = (e) => {
        return (
            <GridActionBar
                isPer={4}
                tooltipDelete={Config.lang("CRM_Xoa")}
                onDeleteAction={() => {
                    this.onDelete(e);
                }}
            />
        );
    };

    renderFileName = (e) => {
        const {data} = e.row;
        /* eslint-disable */
        return (
            <a onClick={() => this.openViewFile(data)} >{data.FileName}</a>
        );
        /* eslint-enable */
    };

    render() {
        const {isUpdate, isSave, currentFileName, txtDescription, openedFile,
            dataAttachments, showFileViewer, total
        } = this.state;

        return (
            <div>
                <Modal open={showFileViewer} maxWidth={"lg"} fullWidth={true}
                       paperProps={{style: {height: '100%'}}}
                       title={Config.lang('DHR_Xem_tap_tin')}
                       onClose={this.onCloseViewFile}>
                    <FileViewer fileName={openedFile.fileName} filePath={openedFile.filePath} fileType={openedFile.fileType} height={'100%'} />
                </Modal>
                <FormGroup>
                    <ActionToolbar className={'mgt0'} style={{paddingBottom: 5}} alignment={'flex-end'}>
                        <ButtonGeneral
                            name={Config.lang('DHR_Luu_va_Nhap_tiep')}
                            typeButton={'save'}
                            className={'pull-right mgr5'}
                            disabled={!isUpdate}
                            size={'small'}
                            onClick={this.onSaveContinue}
                        />
                        <ButtonGeneral
                            name={Config.lang('DHR_Nhap_tiep')}
                            typeButton={'add'}
                            className={'pull-right mgr5'}
                            disabled={!isSave}
                            size={'small'}
                            onClick={this.onContinue}
                        />
                        <ButtonGeneral
                            name={Config.lang('DHR_Luu')}
                            typeButton={'save'}
                            className={'pull-right'}
                            disabled={!isUpdate}
                            size={'small'}
                            onClick={this.onSave}
                        />
                    </ActionToolbar>
                </FormGroup>
                <FormGroup>
                    <input ref={ref => this.inputFiles = ref} type="file" id="file" name="files[]" className="hide"
                           onChange={() => this.onSelectFile()}/>
                </FormGroup>
                <FormGroup className={"row-form"}>
                    <Row>
                        <Col xs={12} sm={10} md={10} lg={10}>
                            <TextField
                                label={Config.lang('DHR_Tap_tin')}
                                variant={"outlined"}
                                value={currentFileName}
                                InputProps={{
                                    readOnly: true,
                                }}
                                disabled={isSave}
                                fullWidth
                                required
                            />
                        </Col>
                        <Col xs={12} sm={2} md={2} lg={2}>
                            <ButtonCustom
                                name={Config.lang('DHR_Chon')}
                                className={'pull-right'}
                                style={{marginTop: 8}}
                                disabled={isSave}
                                fab={true}
                                size={'medium'}
                                icon={<i className="fas fa-folder text-blue" />}
                                onClick={this.onChoose}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={10} md={10} lg={10}>
                            <div className={'display_row flex-wrap align-between'}>
                                <Typography variant={'h6'} style={{fontSize: '0.9rem', color: '#a2a1a1'}}>
                                    {Config.lang("DHR_Loai_tap_tin_ho_tro")}:&nbsp;
                                    <span style={{color: 'red'}}>.doc .docx .jpeg .jpg .pdf .txt</span>
                                </Typography>
                                <Typography variant={'h6'} style={{fontSize: '0.9rem', color: '#a2a1a1'}}>
                                    {Config.lang("DHR_Kich_co_toi_da")}:&nbsp;
                                    <span style={{color: 'red'}}>{this.limitSize} KB</span>
                                </Typography>
                            </div>
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"row-form"}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                label={Config.lang('DHR_Dien_giai')}
                                variant={"outlined"}
                                onChange={this.handleDesChange}
                                value={txtDescription}
                                fullWidth
                                disabled={isSave}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <GridContainer
                        totalItems={total}
                        itemPerPage={this.filter.limit}
                        skipPerPage={this.filter.skip}
                        dataSource={dataAttachments}
                        columnAutoWidth={true}
                        typeShort={true}
                        onChangePage={this.onChangePage}
                        onChangePerPage={this.onChangePerPage}
                    >
                        <Column
                            cellRender={(data) => this.renderAction(data)}
                            caption={Config.lang("DHR_Hanh_dong")}
                            alignment={'center'}
                            allowSorting={false}
                            showInColumnChooser={false}
                            width={'7.25rem'}
                            fixed={true}
                        />
                        <Column
                            caption={Config.lang("DHR_Dinh_kem")}
                            dataField={'FileName'}
                            width={300}
                            cellRender={this.renderFileName}
                        />
                        <Column
                            dataField={'URL'}
                            visible={false}
                        />
                        <Column
                            caption={Config.lang("DHR_Dien_giai")}
                            dataField={'Description'}
                        />
                        <Column
                            caption={Config.lang("DHR_Ngay_tao")}
                            width={150}
                            dataField={'CreateDate'}
                            alignment={"center"}
                        />
                        <Column
                            caption={Config.lang("DHR_Nguoi_tao")}
                            width={150}
                            dataField={'Creator'}
                            alignment={"center"}
                        />
                    </GridContainer>
                </FormGroup>
            </div>
        );
    }
}

W09F0001.propTypes = {
    data: PropTypes.any,

    onSave: PropTypes.func
};

export default compose(connect(null,
    (dispatch) => ({
        w09f0001Actions: bindActionCreators(w09f0001Actions, dispatch),
    })
))(W09F0001);
