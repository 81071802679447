/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/15/2019
 * @Example
 */

import React from "react";
import PropTypes from "prop-types";
import Config from "../../config";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as mainActions from "../../redux/main/main_actions"
import W09F0001 from "./W09F0001";
import Modal from "../common/modal/modal";

class AttachmentPopup extends React.Component {

    onOpen = () => {
        const {onOpen} = this.props;
        if (onOpen) onOpen();
    };

    onClose = () => {
        const {onClose} = this.props;
        if (onClose) onClose();
    };

    render() {
        const {open, data, title, maxWidth, className, style} = this.props;

        return (
            <React.Fragment>
                <Modal open={open ? open : false}
                       style={style}
                       className={className}
                       fullWidth={true}
                       title={title ? title : Config.lang("DHR_Dinh_kem")}
                       maxWidth={maxWidth ? maxWidth : 'md'}
                       onOpen={this.onOpen}
                       onClose={this.onClose}
                >
                    <W09F0001 data={data} />
                </Modal>
            </React.Fragment>
        );
    }
}

AttachmentPopup.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    maxWidth: PropTypes.string,
    data: PropTypes.any,

    onClose: PropTypes.func,
    onOpen: PropTypes.func,
};

export default connect(null,
    (dispatch) => ({
        mainActions: bindActionCreators(mainActions, dispatch)
    }), null, {forwardRef: true}
)(AttachmentPopup);
